import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, Link } from 'react-router-dom'
import navigation from '../data'
import { chakra, Box, VStack, Divider } from '@chakra-ui/react'
import { buttonStyles } from './NavMenu'

const baseStylesMenu = {
	'd': 'block',
	'pt': '1rem',
	'float': 'right',
	'my': '0.5rem',
	'mr': '1rem',
	'& li': {
		display: 'inline-block',
		mr: '1rem',
		position: 'relative',
	},
	'& li ul': {
		display: 'none',
		m: 0,
		pr: '0.5rem',
		pb: '0.5rem',
		pt: '0.5rem',
		backgroundColor: 'white',
		borderRadius: '3px',
		position: 'absolute',
		top: '36px',
		right: '0',
		minW: '185px',
		boxShadow: 'base',
	},
	'& li ul li': {
		display: 'inline-block',
		textAlign: 'right',
		m: 0,
	},
	'& li:hover ul': {
		display: 'block',
	},
}

const baseStylesMenuMobile = {
	'd': 'block',
	'& li': {
		display: 'block',
		mr: 0,
	},
	'& li > ul': {
		display: 'none',
		position: 'relative',
		top: 0,
		boxShadow: 'none',
	},
	'& li > ul > li': {
		display: 'block',
	},
}

const baseStyles_item = {
	display: 'block',
	fontSize: '1rem',
	color: 'black',
	fontWeight: 600,
	py: '1rem',
	px: '0.5rem',
	linHeight: '1.25rem',
	position: 'relative',
	cursor: 'pointer',
	_hover: {
		color: '#b5bcc3',
		cursor: 'pointer',
	},
}

const baseStyles_itemMobile = {
	fontSize: '1rem',
	color: 'black',
	fontWeight: 600,
	lineHeight: '1.25rem',
	position: 'relative',
	display: 'block',
	py: '1rem',
	px: '0.5rem',
	cursor: 'pointer',
	_hover: {
		color: '#b5bcc3',
		cursor: 'pointer',
	},
}

/**
 * Base Menu Component allowing to render items inline (inline-block), or for mobile (list block)
 * This is used for the Vidday home page as well.
 */

const InlineMenu = ({ handleClose, isMobileMode }) => {
	/** Which element has been clicked ? (allow us to keep reference to the parent if it contains children to toggle) */
	const [itemClicked, setItemClicked] = useState(null)
	/** History hook */
	const navigate = useNavigate()

	/** Handler to be used when an item is being clicked */
	const handleClick = (e, id, isExternal, target) => {
		// Prevent Default
		e && e.preventDefault()
		// Let's see if this item is already the same :
		let isSame = itemClicked === id
		// We've clicked an item, let's find which one it is and display children if needed;
		if (id) {
			setItemClicked(() => (isSame ? null : id))
		}
		// Make sure it's not a 'dead' link
		if (target && target !== '#') {
			// If it's a relative path
			if (!isExternal) {
				// close the menu or side navigation !
				handleClose()
				// Scroll back to top of the page
				if (typeof window !== 'undefined' && window) window.scrollTo(0, 0)
				// Redirect user
				navigate(target)
			}
			// Otherwise we can redirect to external target
			else {
				if (window) window.location = e.target.href
			}
		}
	}

	/** Build Navigation view */
	const buildNavigation = () => {
		const buildLink = (el, key) => {
			let link
			if (el.relative && el.to) {
				link = (
					<chakra.a
						key={key}
						as={Link}
						sx={buttonStyles} //sx={isMobileMode ? baseStyles_itemMobile : baseStyles_item }
						// MenuLink
						// mobile={isMobileMode ? `true` : `false`}
						to={el.to}
						title={el.title}
						// isdropdown={el.children ? `true` : `false`}
						// child={`${isChild}` || `false`}
						onClick={(e) => handleClick(e, el.id, false, el.to || '#')}>
						{el.title}
					</chakra.a>
				)
			} else {
				link = (
					<chakra.a
						key={key}
						sx={buttonStyles}
						//sx={isMobileMode ? baseStyles_itemMobile : baseStyles_item }
						href={el.to || '#'}
						title={el.title}
						// isdropdown={el.children ? `true` : `false`}
						// child={`${isChild}` || `false`}
						onClick={(e) => handleClick(e, el.id, true, el.to)}>
						{el.title}
					</chakra.a>
				)
			}
			return link
		}

		let menuItems = navigation.map((original) => {
			// If the link is relative to the app and that a destination path has been provided
			// let childrenView = original.children
			// 	? original.children.map((child) => {
			// 			return <li key={child.id}>{buildLink(child, true)}</li>
			// 	  })
			// 	: null
			return buildLink(original, original.id)
			// <li key={original.id}>
			// 	{}
			// 	{/* {childrenView ? (
			// 		<ul className={itemClicked == original.id ? 'visible' : ''}>{childrenView}</ul>
			// 	) : null} */}
			// </li>
			// )
		})
		return menuItems
	}

	/** If we're building the menu for mobile, render a different menu  */
	if (isMobileMode) {
		return (
			<Box sx={baseStylesMenuMobile}>
				<Divider variant="dashed" my="1rem" />
				<VStack
					flexDirection="column"
					spacing="0.25rem"
					my="1.5rem"
					textAlign={['center', 'right']}
					alignItems="center">
					{buildNavigation()}
				</VStack>
				<Divider variant="dashed" my="1rem" />
			</Box>
		)
	}
	/** Otherwise proceed by default with inline menu */
	return (
		<Box sx={baseStylesMenu}>
			<Divider variant="dashed" my="1rem" />
			<VStack
				flexDirection="column"
				spacing="0.25rem"
				my="1.5rem"
				textAlign={['center', 'right']}
				alignItems="center">
				{buildNavigation()}
			</VStack>
			<Divider variant="dashed" my="1rem" />
		</Box>
	)
}

InlineMenu.defaultProps = {
	/** We want to enforce passing a handleClose value */
	handleClose: undefined,
	/** By default, let's render the inline menu */
	isMobileMode: false,
}

InlineMenu.propTypes = {
	/** Callback Handler to call after an item has been clicked by the user */
	handleClose: PropTypes.func.isRequired,
	/** Using this variable, we know if we want to build an inline navigation (display:inline-block) or a mobile navigation (display:block) */
	isMobileMode: PropTypes.bool.isRequired,
}

export default InlineMenu
